var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useRef } from 'react';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import Sidebar from '@shared/ui/sidebars/Sidebar';
import { Wizard } from '@shared/ui/misc/Wizard';
import Initial from './steps/Initial';
import Confirmation from './steps/Confirmation';
import Success from './steps/Success';
const DeleteAccount = (_a) => {
    var { isOpen, onClose = () => { } } = _a, props = __rest(_a, ["isOpen", "onClose"]);
    const successStepRef = useRef(null);
    const onSidebarClose = () => {
        var _a;
        if ((_a = successStepRef === null || successStepRef === void 0 ? void 0 : successStepRef.current) === null || _a === void 0 ? void 0 : _a.onClose) {
            successStepRef.current.onClose();
        }
        else {
            onClose();
        }
    };
    return (_jsx(Sidebar, Object.assign({ onClose: onSidebarClose, isOpen: isOpen, shouldCloseOnOutsideClick: true, destroyOnHide: true }, props, { children: _jsx(SidebarInner, { displayCancelBtn: true, cancelBtnProps: { onClick: onSidebarClose }, children: _jsxs(Wizard, { children: [_jsx(Initial, { onCancel: onClose }), _jsx(Confirmation, { onCancel: onClose }), _jsx(Success, { ref: successStepRef })] }) }) })));
};
export default DeleteAccount;
