import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useWizard } from '@shared/ui/misc/Wizard';
import { useToastNotification } from '@shared/ui/overlay/Toast';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { BodyMedium, BodyMediumBold, TextEmphasizedMediumBold } from '@components/styled/Typography';
import Button from '@shared/ui/buttons/Button';
import { useLazyGetResetPasswordLinkQuery } from '@shared/api/auth';
import { useAppSelector } from '@store/Hooks';
const Container = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: '100%',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.l,
    maxWidth: '420px',
}));
const Content = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.xl,
}));
const Title = TextEmphasizedMediumBold;
const InfoContainer = styled(ContainerColumn)((props) => ({
    width: '100%',
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const InfoRow = styled(ContainerRow)((props) => ({
    width: '100%',
    height: 'auto',
    boxSizing: 'border-box',
    padding: '18px',
    gap: '10px',
    borderRadius: props.theme.shape.borderRadius,
    backgroundColor: '#F8FAFF',
}));
const InfoRowIndex = styled(BodyMediumBold)((props) => ({
    color: props.theme.palette.primary.main,
}));
const InfoRowText = styled(BodyMedium)((props) => ({
    color: props.theme.palette.text.primary,
}));
const SendRecoveryLink = () => {
    const { t } = useTranslation();
    const { goNext } = useWizard();
    const { toast } = useToastNotification();
    const { emailForReset } = useAppSelector(state => state.auth);
    const [getPasswordLink, { isLoading: isResetting, isSuccess, isError, error, },] = useLazyGetResetPasswordLinkQuery();
    const sendResetPasswordLink = () => {
        if (emailForReset) {
            getPasswordLink({ email: emailForReset });
        }
        else {
            toast({
                message: 'Internal error: emailForReset was not set',
                variant: 'error',
            });
        }
    };
    if (isSuccess) {
        goNext();
    }
    if (isError) {
        toast({
            message: JSON.stringify(error),
            variant: 'error',
        });
    }
    return (_jsxs(Container, { children: [_jsxs(Content, { children: [_jsx(Title, { children: t('change_password.change_password', { ns: 'common' }) }), _jsxs(InfoContainer, { children: [_jsxs(InfoRow, { children: [_jsx(InfoRowIndex, { children: "1." }), _jsx(InfoRowText, { children: t('change_password.click_and_we_send_link', { ns: 'common' }) })] }), _jsxs(InfoRow, { children: [_jsx(InfoRowIndex, { children: "2." }), _jsx(InfoRowText, { children: t('change_password.follow_the_link', { ns: 'common' }) })] })] })] }), _jsx(Button, { variant: 'filled', color: 'primary', isLoading: isResetting, disabled: isResetting, onClick: sendResetPasswordLink, fullWidth: true, children: t('change_password.send_link', { ns: 'common' }) })] }));
};
export default SendRecoveryLink;
